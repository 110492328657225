import React, { useEffect, useState } from "react";
import { Button, Input } from "../CustomComponent/StyledComponent";
import reg_style from "../../css/register.module.css";
import login_style from "../../css/login.module.css";
import { checkValidEmailOrPhone, getLoginOTP, login } from "../../controller/authController";
import dayjs from "dayjs";
import { globalStorage } from "../../utils/Storage";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { generateSearchTag } from "../../controller/globalController";
import Loading from "../Loading/Loading";
import { useSelector } from "react-redux";
import useDebounce from "../../utils/useDebounce";

// component
const UserNameInput = ({ username, changeHandler, loading }) => {
  return (
    <div className="form-group" style={{ margin: "5px 0" }}>
      <label htmlFor="email_hp" style={{ margin: "0", fontSize: "0.9rem" }}>
        Nomor Hp atau E-mail
      </label>
      <Input
        type="text"
        id="email_hp"
        className="form-control"
        name="username"
        value={username}
        onChange={(e) => changeHandler(e)}
        autoFocus={true}
        disabled={loading}
      />
      <label htmlFor="email_hp" style={{ margin: "0", fontSize: "0.7rem" }}>
        Contoh : email@1toko.com
      </label>
    </div>
  );
};

const OTPInput = ({ otp, changeHandler, loading }) => {
  return (
    <>
      <div className={reg_style.otp_message}>
        {`OTP telah dikirimkan ke HP Anda melalui WhatsApp, silahkan memasukkan input OTP Anda`}
      </div>
      <div className="form-group" style={{ margin: "5px 0" }}>
        <Input
          type="text"
          id="otp"
          className="form-control"
          name="otp"
          value={otp}
          onChange={(e) => changeHandler(e)}
          style={{ textAlign: "center" }}
          autoFocus={true}
          maxLength={6}
          disabled={loading}
        />
      </div>
    </>
  );
};

const PasswordInput = ({ password, showPass, changeHandler, setShowPass, loading, login_flag }) => {
  return (
    <div className={`form-group ${login_style.password_container}`}>
      <label htmlFor="password" style={{ margin: "0", fontSize: "0.9rem" }}>
        Kata Sandi
      </label>
      <div className="input-group">
        <Input
          type={showPass ? "text" : "password"}
          id="password"
          className={`form-control ${login_style.password_input}`}
          name="password"
          value={password}
          onChange={(e) => changeHandler(e)}
          autoFocus={true}
          disabled={loading}
        />
        <div className="input-group-append">
          <span className={`input-group-text`} id="basic-addon">
            <i
              className={showPass ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"}
              onClick={() => setShowPass(!showPass)}
            />
          </span>
        </div>
      </div>
      <a
        className={login_style.forgotPass}
        href={"/reset_password"}
        style={{ color: login_flag ? "#583985" : "var(--main_color)" }}
      >
        Lupa Kata Sandi ?
      </a>
    </div>
  );
};

// main
const InitState = {
  otp: "",
  username: "",
  password: "",
  error: "",
  login_step: 1,
  is_loading: false,
  is_valid_username: false,
  is_hp_or_email: "",
};

function LoginForm() {
  const storeData = useSelector((s) => s.global.storeData);
  const muliproFlag = [308, 27, 46].includes(storeData?.id ?? undefined)

  const [state, setState] = useState(InitState);
  const [showPass, setShowPass] = useState(false);
  const [time, setTime] = useState(120000);
  const location = useLocation();
  const navigate = useNavigate();

  const { username, password, error, otp, is_hp_or_email } = state;
  const login_step = parseInt(state.login_step);
  const login_flag = location.pathname === "/login" && !muliproFlag;
  const hp_flag = is_hp_or_email === "phone";
  const subheader_flag = !hp_flag || login_step === 1;
  const _username = hp_flag ? username.replace(/^0+/, "+62") : username;
  const error_flag = error !== "";

  const subheader_style = `${login_style.sub_header} ${login_flag ? login_style.page_sub_title : ""}`;
  const color = storeData ? storeData.color_code : "#583985"

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeHandler = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value, error: "" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (login_step === 1) {
      if (is_hp_or_email === "phone") {
        changeState({ is_loading: true, error: "" });
        const otp_res = await getLoginOTP(_username);
        if (otp_res) changeState({ login_step: 2, is_loading: false });
        else changeState({ is_loading: false });
      }
      if (is_hp_or_email === "email") changeState({ login_step: 2 });
    }

    if (login_step === 2) {
      changeState({ is_loading: true, error: "" });
      const login_res = await login(state);
      if (login_res.error === "" && login_res.userData !== undefined) {
        globalStorage.setItem("UD", login_res.userData);
        globalStorage.setItem("STag", generateSearchTag());

        const prev = location.state?.prev ?? "/";
        if (login_flag) navigate(prev, { replace: true });
        else navigate(0);
      } else {
        changeState({ error: login_res.error, is_loading: false });
      }
    }
  };

  const showTimer = () => {
    const duration = require("dayjs/plugin/duration");
    dayjs.extend(duration);
    let minutes = "0" + dayjs.duration(time).minutes();
    let seconds = "0" + dayjs.duration(time).seconds();
    return `${minutes.substring(minutes.length - 2)} : ${seconds.substring(
      seconds.length - 2
    )}`;
  };

  // useEffect
  useDebounce(() => {
    // validasi username
    const validate = checkValidEmailOrPhone(username);
    if (!validate.is_valid_username) {
      validate.login_step = 1;
      validate.password = "";
    }
    changeState(validate);
  }, 500, [username]);

  useEffect(() => {
    //timer re-req otp
    let timer = null;
    if (login_step === 2 && hp_flag) {
      timer = setTimeout(() => {
        if (time > 0) setTime(time - 1000);
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <>
      {subheader_flag && (
        <div className={subheader_style}>
          <div>{login_flag ? "Masuk" : ""}</div>
          <Link to="/register">Daftar</Link>
        </div>
      )}
      <form style={{ width: "100%" }}>
        {(login_step === 1 || is_hp_or_email !== "phone") && (
          <UserNameInput
            username={username}
            changeHandler={changeHandler}
            loading={state.is_loading}
          />
        )}

        {/* step 2 */}
        {login_step === 2 &&
          (is_hp_or_email === "email" ? (
            <>
              <PasswordInput
                password={password}
                changeHandler={changeHandler}
                showPass={showPass}
                setShowPass={setShowPass}
                loading={state.is_loading}
                login_flag={login_flag}
              />
            </>
          ) : (
            is_hp_or_email === "phone" && (
              <>
                <OTPInput
                  otp={otp}
                  changeHandler={changeHandler}
                  loading={state.is_loading}
                />
                <div className={reg_style.timer_container}>
                  {time > 0 ? (
                    `Kirim ulang OTP dalam waktu ${showTimer()}`
                  ) : (
                    <div
                      onClick={() => {
                        getLoginOTP(_username);
                        setTime(120000);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      kirim ulang OTP
                    </div>
                  )}
                </div>
              </>
            )
          ))}

        <label
          className={login_style.error_lable}
          style={{ display: error_flag ? "block" : "none" }}
        >
          {error}
        </label>
        {state.is_loading ? (
          <div style={{ marginTop: "0.5rem" }}>
            <Loading />
          </div>
        ) : (
          <Button
            active
            type={"submit"}
            onClick={(e) => handleSubmit(e)}
            style={{ marginTop: "0.5rem" }}
            bg_color={`${color} !important`}
            disabled={!state.is_valid_username}
          >
            {login_step === 2 ? "Masuk" : "Selanjutnya"}
          </Button>
        )}
      </form>
    </>
  );
}

export default LoginForm;
